import React, { useEffect } from "react";
import { useState } from "react";
// import Image from "../Rander/Cyber-island-screen.gif";
import { useNavigate} from "react-router-dom";
import { UrlLogin } from "../../redux/action/action";
import { connect } from "react-redux";
import {
  ClientId,
  SecretId,
  API_Prefix,
} from "../config/config";
let server_url = `${API_Prefix}/o/`;
let data = `grant_type=client_credentials&client_id=${ClientId}&client_secret=${SecretId}`;
let accessToken = null;

const SplashScreen = (props) => {
  var vars;
  const navigate = useNavigate();
  var query = window.location.search.substring(1);
  if(query!=="")
  {
     vars = query.split("&");
  }
  useEffect(() => {
    // POST request to get Token using fetch()
    fetch(server_url + "oauth2/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: data,
    })
      .then((response) => response.json())
      .then((json) => {
        accessToken = json.access_token;
      });
  }, []);

  const handleNavigation = (e) => {
    fetch(server_url + "oauth2/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: data,
    })
      .then((response) => response.json())
      .then((json) => {
        accessToken = json.access_token;
      });
   if(query !=="")
   {
    if(vars.length > 0)
    {
    props.UrlLogin(vars,accessToken);
    }
   }
    else{
      navigate({ pathname: "/login" });
    }
  };

  useEffect(()=>{
    if(props.urlloginresponse && props.urlloginresponse.urlLoginResponse && props.urlloginresponse.urlLoginResponse.userName!==null)
    {
      navigate({ pathname: "/cyberIsland" });
    }
  },[props.urlloginresponse]);

 
  return (
    <div className="container-fluid p-0 splash-screen">
      <div className="d-flex vh-100">
        <div className="splash">
          {/* <img src={Image} alt="Logo" /> */}
          {/* <p className="cyber-text">Cyberisland a quest</p> */}
          <button className="splash-btn" onClick={() => handleNavigation()}>
            Start
          </button>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state)=>{
  return{
    urlloginresponse: state.GameReducer.urlloginresponse,
  }
}
export default connect(mapStateToProps, { UrlLogin })(SplashScreen);
