import * as action_types from "../actionTypes";
import axios from "axios";
import { API_Prefix } from "../../component/config/config";
import { ClientId, SecretId } from "../../component/config/config";
let server_url = `${API_Prefix}/o/`;
let data = `grant_type=client_credentials&client_id=${ClientId}&client_secret=${SecretId}`;
let Token = null;
let newToken = null;
let storeAuthtoken = null;
let ss = null ,vv = null ;
var storedomain;

export const Login = (data, accessToken) => {
  Token = accessToken;
  storedomain = data.email;
  const config_req = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  return (dispatch) => {
    axios
      .get(
        `${API_Prefix}/o/game/register-game-user?enduserName=${data.name}&enduserEmail=${data.email}&token=${data.token}`,
        config_req
      )
      .then((response) => {
      
        dispatch({
          type: action_types.LOGIN,
          payload: response,
        });
      });
  };
};

// Auth Token ..................
export const AuthToken = (accessToken, authToken) => {

  const config_req = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  return (dispatch) => {
    axios
    .get(
      `${API_Prefix}/o/game/validate-token?authToken=${authToken}`,
      config_req
    )
     .then((response) => {
        dispatch({
          type: action_types.AUTH_TOKEN,
          payload: response,
        });
      });
  };
};
// Admin Login ..................
export const Adminlogin = (data, accessToken) => {
  Token = accessToken;
  const config_req = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  return (dispatch) => {

    const payload = {
      username: data.username,
      password: data.password,
      captchaToken: data.captchaToken,
    }

    axios
      .post(API_Prefix + `/o/game/admin-login`, payload,config_req
      )
      .then((response) => {
      
        storeAuthtoken = response.data.authToken;
        sessionStorage.setItem("token", storeAuthtoken);
        dispatch({
          type: action_types.ADMIN_LOGIN,
          payload: response,
        });
      });
  };
};
// Admin Competition Search-User ..........
export const AdminCompetitonSearch = (name,email, accessToken, authToken) => {
  Token = accessToken;
  const config_req = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  return (dispatch) => {
    const payload = {
      authToken: authToken,
      searchEmail: email,
      searchName: name,
    }
    axios
      .post(API_Prefix + `/o/game/search-user`, payload,config_req
      )
      .then((response) => {
       
        dispatch({
          type: action_types.SEARCH_USER,
          payload: response,
        });
      });
  };
};
// Admin Competition Search-User ..........
export const AdminCompetitonSearch2 = (name,email, accessToken) => {
  Token = accessToken;
  const config_req = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  return (dispatch) => {
    const payload = {
      authToken: storeAuthtoken,
      searchEmail: email,
      searchName: name,
    }
    axios
      .post(API_Prefix + `/o/game/search-user`, payload,config_req
      )
      .then((response) => {
      
        dispatch({
          type: action_types.SEARCH_USER_PLAYER2,
          payload: response,
        });
      });
  };
};
// Admin-logout.............
export const AdminLogout = (accessToken) => {
  Token = accessToken;
  const config_req = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  return (dispatch) => {    
    axios
    .get(
      `${API_Prefix}/o/game/logout?authToken=${storeAuthtoken}`,
      config_req
    )
      .then((response) => {
        dispatch({
          type: action_types.ADMIN_LOGOUT,
          payload: response,
        });
      });
  };
};
//Admin check-user-status...........
export const AdminCheckUserStatus = (prGameAttendeeId,accessToken, authToken) => {

  Token = accessToken;
  const config_req = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  return (dispatch) => {

    if(storeAuthtoken!==null)
    {
    const payload = {
      authToken: authToken,
      prGameAttendeeId: prGameAttendeeId
    }
    axios
      .post(API_Prefix + `/o/game/check-user-status`, payload,config_req
      )
      .then((response) => {
        dispatch({
          type: action_types.ADMIN_CHECK_STATUS,
          payload: response,
        });
      });
    }
  };
};
//Admin check-user-status...........
export const AdminCheckUserStatus2 = (prGameAttendeeId,accessToken, authToken) => {

  Token = accessToken;
  const config_req = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  return (dispatch) => {

    if(storeAuthtoken!==null)
    {
    const payload = {
      authToken: authToken,
      prGameAttendeeId: prGameAttendeeId
    }
    axios
      .post(API_Prefix + `/o/game/check-user-status`, payload,config_req
      )
      .then((response) => {
        dispatch({
          type: action_types.ADMIN_CHECK_STATUS_SECOND,
          payload: response,
        });
      });
    }
  };
};
// top-users-of-the-day.........
export const TopUsersOfDay = (email,accessToken,topuserRefreshing) => {

  Token = accessToken;
  const config_req = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  return (dispatch) => {
    let storeResponse;
    topuserRefreshing = false;
    dispatch({
      type: action_types.TOP_USERS_OF_DAY,
      payload: {storeResponse,topuserRefreshing}
    });

    const payload = {
      authToken: storeAuthtoken,
      domain: email
    }
    axios
      .post(API_Prefix + `/o/game/fetch-top-users-of-the-day`, payload,config_req
      )
      .then((response) => {
        topuserRefreshing = true;
        storeResponse = response;
        dispatch({
          type: action_types.TOP_USERS_OF_DAY,
          payload: {storeResponse,topuserRefreshing}
        });
      });
  };
};
export const UrlLogin = (urlParams,accessToken) => {
  const config_req = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  return async (dispatch) => {
   ss =  urlParams[0].split("=");
   vv =  urlParams[1].split("=");
    const payload = {
    gameUserId: ss[1],
    accountUserId: vv[1]
    };
   await axios
      .post(API_Prefix + `/o/game/login-user`, payload, config_req,
      )

      .then((response) => {
        const urlLoginResponse = response.data;
        dispatch({
          type: action_types.URL_PARAMS,
          payload: {payload,urlLoginResponse},
        });
      });
  };
};
export const PhishingModule = (score, phishingdetourcount) => {
  return (dispatch) => {
    const setPhishing = {
      score,
      phishingdetourcount,
    };
    dispatch({
      type: action_types.PHISHINGMODULE,
      payload: { setPhishing },
    });
  };
};
export const MalwareModule = (score, malwaredetourcount) => {
  return (dispatch) => {
    const setMalware = {
      score,
      malwaredetourcount,
    };
    dispatch({
      type: action_types.MALWAREMODULE,
      payload: { setMalware },
    });
  };
};
export const RansomewareModule = (score, ransomewaredetourcount) => {
  return (dispatch) => {
    const setRansomeware = {
      score,
      ransomewaredetourcount,
    };
    dispatch({
      type: action_types.RANSOMEWAREMODULE,
      payload: { setRansomeware },
    });
  };
};
export const InsiderThreatModule = (score, insiderthreatdetourcount) => {
  return (dispatch) => {
    const setInsider = {
      score,
      insiderthreatdetourcount,
    };
    dispatch({
      type: action_types.INSIDERTHREAT,
      payload: { setInsider },
    });
  };
};
export const SocialEngineeringModule = (
  score,
  socialengineeringdetourcount,
  counter
) => {
  return (dispatch) => {
    const setSocial = {
      score,
      socialengineeringdetourcount,
      counter
    };
    dispatch({
      type: action_types.SOCIALENGINEERINGMODULE,
      payload: { setSocial },
    });
  };
};
export const DenialServiceModule = (score, denailservicedetourcount) => {
  return (dispatch) => {
    const setDenail = {
      score,
      denailservicedetourcount,
    };
    dispatch({
      type: action_types.DENIALSERVICE,
      payload: { setDenail },
    });
  };
};

// Game Result API......................
export const GameResult = (result) => {
  return async (dispatch) => {
   await fetch(server_url + "oauth2/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: data,
      
    })
      .then((response) => response.json())
      .then((json) => {
        newToken = json.access_token;
      });
    const config_req = {
      headers: { Authorization: `Bearer ${newToken}` },
    };

    if (ss !== null && vv !== null) {
      const payload ={
        gameUserId: ss[1],
        accountUserId: vv[1]
      };
      result = { ...result, ...payload };
    }

    axios
      .post(API_Prefix + `/o/game/add-game-results`, result, config_req)
      .then((response) => {
        dispatch({
          type: action_types.RESULT,
          payload: response,
        });
      });
  };
};
