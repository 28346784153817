import React, { useState, useEffect } from "react";
import Spinner from "../spinner/spinner";
import Questionaire from "./questionaire";
import {useNavigate } from "react-router-dom";
import {connect} from "react-redux";
import {RansomewareModule, GameResult} from "../../redux/action/action";
import { Data } from "../data/ransomwareData";
import Image1 from "../Rander/ransomeware-boat.png";
// import Flag from "../Rander/flags/ransomeware.gif";

let detourCounter = 0;
function Question(props) {

  let result = null;
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [showAnswers, setShowAnswers] = useState(false);
  const [wrongAnswers, setWrongAnswers] = useState(false);
  const [time, setTime] = useState(false);
  const [firstIndex, setForFirstIndex] = useState(true);
  const [counter, setCounter] = useState(0);
  const [running, setRunning] = useState(false);

   // Calculate Time...............
   useEffect(() => {
    let interval;

    if (!running) {
      return () => {};
    }
    interval = setInterval(() => {
      setCounter((counter) => counter + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [running]);


  useEffect(() => {
       const data = Data();
       const quiz_questions = data.map((question) => ({
          ...question,
          answers: [
            question.correct_answer,
            ...question.incorrect_answers,
          ].sort(() => Math.random() - 0.5),
        })).sort(() => Math.random() - 0.5);
        setQuestions(quiz_questions);
  }, []);

  const handleAnswer = (answer) => {
    setRunning(true);
    if (!showAnswers) {
      if (answer === questions[currentIndex].correct_answer) {
        setScore(score + 1);
        setWrongAnswers(false);
      }
      else{
        setWrongAnswers(true);
      }
    }    
    setShowAnswers(true);
    handleNextQuestion();
  };

  const handleNextQuestion = () => {
    setCurrentIndex(currentIndex + 1);
    setShowAnswers(false);
    if(currentIndex === 1)
    {
      setTimeout(() => {
        setTime(true);
      }, 2000);
    }
    if(currentIndex === 1 && score === 0)
    {
      setForFirstIndex(false);
    }
  };
  const handleDetour = () => {
    detourCounter = detourCounter + 1;
    navigate({pathname:"/ransomewaretoolkit",});
    setScore(0);

  }
  const handleNextModule = () => {
    props.RansomewareModule(score,detourCounter);
    const ransomewarePercent = (score / 2) * 100;

    result = {
      prGameAttendeeId: (props.prGameAttendeeId &&
          props.prGameAttendeeId.data &&
          props.prGameAttendeeId.data.prGameAttendeeId) || null,
          prGameAttendeeAttemptId:(props.prGameAttendeeAttemptId &&
            props.prGameAttendeeAttemptId.data &&
            props.prGameAttendeeAttemptId.data.prGameAttendeeAttemptId) || null,
            moduleResults: 
            {
              moduleName: "RANSOMEWARE",
              totalQuestions: 2,
              correctAnswers: score,
              scorePercent: ransomewarePercent,
              timeSpentInSeconds:counter
            },
          
      // accountUserId: "",
      // gameUserId: ""
    };
    props.GameResult(result);
    navigate({pathname:"/socialengineeringmodule",
  });
  }

  return (
    <>
    {currentIndex === 2 && wrongAnswers === false && score === 2 && time === true ? 
    <>
      <img src="https://dqib2w4u3w0ne.cloudfront.net/Rander/flags/ransomeware.gif" alt="Logo" className="container-fluid p-0 set-height" />
      <button onClick = {handleNextModule} className="next-module">Next</button> 
      </>
    :
    <div className="container-fluid p-0 set-height">
      {currentIndex === 0 &&
       <img src={Image1} alt="Logo" className="set-boat8" />
      }
      {currentIndex === 1 &&  wrongAnswers === false && score === 1  ?
       <img src={Image1} alt="Logo" className="set-boat9" />
      :
      currentIndex === 1 && wrongAnswers === true && score === 1 &&
      <img src={Image1} alt="Logo" className="set-boat9-move" />
      }
      {currentIndex === 2 &&  wrongAnswers === false && score === 2  ?
       <img src={Image1} alt="Logo" className="set-boat10" />
      :
      currentIndex === 2 && wrongAnswers === true && (score === 2 || score === 1) &&
      <img src={Image1} alt="Logo" className="set-boat10-move" />
      }
      {currentIndex === 2 &&  wrongAnswers === false && score === 1 &&
       <img src={Image1} alt="Logo" className="set-boat10-from-index" />
      }
      {/* {currentIndex === 3 && wrongAnswers === false && score === 3 &&
        <img src={Image1} alt="Logo" className="set-boat11" />
      }
      {currentIndex === 3 && wrongAnswers === false && score === 2 && firstIndex === true ?
        <img src={Image1} alt="Logo" className="set-boat11-not-island" />
      :
      currentIndex === 3 && wrongAnswers === false && score === 2 && firstIndex === false &&
        <img src={Image1} alt="Logo" className="set-boat11-not-island" />
      } */}
       {currentIndex === 2 && wrongAnswers === true && score === 2 ?
       <>
         <img src={Image1} alt="Logo" className="set-boat11-move" />
         <div className="result-fail">
         <h1>
           You're Lost !
         </h1>
          <button onClick = {handleDetour} className="detour">Sail Back</button> 
         </div>
       </>
       :
       currentIndex === 2 && wrongAnswers === true && score === 1 && firstIndex === false &&
       <img src={Image1} alt="Logo" className="set-boat10-move" />
       }
      {/* {currentIndex === 3 && wrongAnswers === true && score === 1 && firstIndex === true &&
        <img src={Image1} alt="Logo" className="set-boat10-move" />
      }
      {currentIndex === 3 && wrongAnswers === false && score === 1 &&
        <img src={Image1} alt="Logo" className="set-boat9" />
      } */}
      {wrongAnswers === true && score === 0 &&
       <img src={Image1} alt="Logo" className="set-boat9-move" />
      }
      {questions.length > 0 ? (
        <>
          {currentIndex === 2 && score !== 2 &&
            <div className="result-fail">
            <h1>
              You're Lost !
            </h1>
             <button onClick = {handleDetour} className="detour">Sail Back</button> 
            </div>
          }
            {currentIndex!==2 &&
            <Questionaire
              handleAnswer={handleAnswer}
              showAnswers={showAnswers}
              handleNextQuestion={handleNextQuestion}
              data={questions[currentIndex]}
            />
            }
        </>
      ) : (
        <div className="container set-center">
          <Spinner />
        </div>
      )}
    </div>
    }
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    urlloginresponseresult:state.GameReducer.urlloginresponseresult,
    prGameAttendeeId: state.GameReducer.login,
    prGameAttendeeAttemptId: state.GameReducer.login,
  };
};
export default connect(mapStateToProps, {RansomewareModule, GameResult}) (Question);
