import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./css/main.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import ReduxThunk from "redux-thunk";
import AllReducers from './redux/reducer/allReducers';
import { Provider } from "react-redux";
import { legacy_createStore as createStore, applyMiddleware } from "redux";

const store = createStore(AllReducers, {}, applyMiddleware(ReduxThunk));
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  
  <Provider store={store}>
    <App />
  </Provider>
);
reportWebVitals();
