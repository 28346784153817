import React,{ useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from "react-router-dom";
import CompetitionScreen from '../competitionscreen/competitionScreen';

const NavbarCustom = (props) =>  {

  const token = sessionStorage.getItem("token",null);
  const [competitionstate, setcompetitionstate] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleLeaderBoard=()=>{
  props.setleaderstate(true);
  // navigate({ pathname: "/leaderboard" });
  }

  const handleCompetition = ()=>{
    props.setleaderstate(false);
    navigate({ pathname: `/admin?${token}` });
  }

  return (
    <>
      <a onClick={handleShow}>
        <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/icons-04.png' className='menu-icon mt-3' title='Menu' />
      </a>
    
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header className='cs-navbar' closeButton>
          <Offcanvas.Title>Cyber Island</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <button className='menu-buttons mt-4' onClick={handleLeaderBoard}>Leader Board</button>
          <br /><br></br>
          <button className='menu-buttons'  onClick={handleCompetition}>Competition</button>
        </Offcanvas.Body>
      </Offcanvas> 
    </>
  );
}

export default NavbarCustom;