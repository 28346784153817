import React from 'react';
import {useNavigate } from "react-router-dom";
// import Image from "../Rander/Cyber-island-screen.gif";
// import PhishingMap from "../Rander/map.gif";
// import Image1 from "../Rander/boat3.png";
import Image1 from "../Rander/malwareboat.png";

const SocialEngineeringMap = () => {

const navigate = useNavigate();

const handleNavigation = () => {
  navigate({pathname:"/socialengineeringtoolkit",
});
}
  return (
  <div className="container-fluid p-0 phishing-module">
  <img src={Image1} alt="Logo" className="set-boat-denailservice"/>
  <div className="d-flex vh-100">
    <div className="splash">
      {/* <img src={Image} alt="Logo" /> */}
      <button className="splash-navigation-map-clue" onClick={() => handleNavigation()}>
        Clue
      </button>
      {/* <button className="splash-toolkit-btn" onClick={() => handleNavigation()}>
        ToolKit
      </button> */}
      {/* <img className='phishing-map' src={PhishingMap} alt="Logo" /> */}
    </div>
  </div>
</div>
  )
}
export default SocialEngineeringMap;
