import React,{ useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";
import { AdminLogout } from "../../redux/action/action";
import { useNavigate } from "react-router-dom";
import {
  ClientId,
  SecretId,
  API_Prefix
} from "../config/config";
let server_url = `${API_Prefix}/o/`;
let data = `grant_type=client_credentials&client_id=${ClientId}&client_secret=${SecretId}`;
let accessToken = null;


const LogoutCustom = (props) =>  {

  const navigate = useNavigate();
  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

    // if(props && props.adminLogout && props.adminLogout.data && props.adminLogout.data.status === "Success")
    // {
    //   navigate({ pathname: "/admin" });
    // }
    React.useEffect(() => {
      // POST request to get Token using fetch()
      fetch(server_url + "oauth2/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          accessToken = json.access_token;
        });
    }, []);

  const handleLogout =()=>{
    props.AdminLogout(accessToken);
    navigate({ pathname: "/admin" });
    window.location.reload(false);
  }

  return (
    <>
      {/* <a onClick={handleShow3}>
        <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/icons-02.png' className='menu-icon mt-3 mx-2' title='Settings'/>
      </a> */}
      <a>
        <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/icons-01.png' className='menu-icon mt-3 mx-3' title='Logout' onClick={handleLogout}/>
      </a>
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="pb-4" controlId="exampleForm.ControlInput1">
              {/* <Form.Label>Player Name</Form.Label> */}
              <Form.Control
                type="text"
                placeholder="Player Name"
                
              />
            </Form.Group>
            <Form.Group className="" controlId="exampleForm.ControlInput2">
              {/* <Form.Label>Email address</Form.Label> */}
              <Form.Control
                type="email"
                placeholder="Player Email"
                
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className='custom-btn' onClick={handleClose3}>
            Close
          </Button>
          <Button className='custom-btn' onClick={handleClose3}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
const mapStateToProps = (state)=>{
  return{
    adminLogout: state.GameReducer.adminLogout
  }
}
export default connect(mapStateToProps, { AdminLogout })(LogoutCustom);
