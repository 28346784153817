import React, { useState, useEffect } from "react";
import Spinner from "../spinner/spinner";
import Questionaire from "./questionaire";
import {useNavigate } from "react-router-dom";
import {connect} from "react-redux";
import {SocialEngineeringModule} from "../../redux/action/action";
import { Data } from "../data/socialengineeringData";
// import Image1 from "../Rander/boat3.png";
import Image1 from "../Rander/malwareboat.png";
import Result from "../modulesresult/result";
// import Flag from "../Rander/flags/engineering.gif";

let detourCounter = 0;
function Question(props) {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [showAnswers, setShowAnswers] = useState(false);
  const [wrongAnswers, setWrongAnswers] = useState(false);
  const [time, setTime] = useState(false);
  const [firstIndex, setForFirstIndex] = useState(true);
  const [counter, setCounter] = useState(0);
  const [running, setRunning] = useState(false);

   // Calculate Time...............
   useEffect(() => {
    let interval;

    if (!running) {
      return () => {};
    }
    interval = setInterval(() => {
      setCounter((counter) => counter + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [running]);

  useEffect(() => {
       const data = Data();
       const quiz_questions = data.map((question) => ({
          ...question,
          answers: [
            question.correct_answer,
            ...question.incorrect_answers,
          ].sort(() => Math.random() - 0.5),
        })).sort(() => Math.random() - 0.5);
        setQuestions(quiz_questions);
  }, []);

  const handleAnswer = (answer) => {
   setRunning(true);
    if (!showAnswers) {
      if (answer === questions[currentIndex].correct_answer) {
        setScore(score + 1);
        setWrongAnswers(false);
      }
      else{
        setWrongAnswers(true);
      }
    }    
    setShowAnswers(true);
    handleNextQuestion();
  };

  const handleNextQuestion = () => {
    setCurrentIndex(currentIndex + 1);
    setShowAnswers(false);
    if(currentIndex === 1)
    {
      props.SocialEngineeringModule(score+1,detourCounter, counter);
      setTimeout(() => {
        setTime(true);
      }, 2000);
    }
    if(currentIndex === 1 && score === 0)
    {
      setForFirstIndex(false);
    }
  };
  const handleDetour = () => {
    detourCounter = detourCounter + 1;
    navigate({pathname:"/socialengineeringtoolkit",});
    setScore(0);
  }
  const handleNextModule = () => {
    props.SocialEngineeringModule(score,detourCounter);
    navigate({pathname:"/denailservicemodule",
  });
  }
 
 
  return (
    <>
    {currentIndex === 2 && wrongAnswers === false && score === 2 && time === true ? 
      <Result/>
    :
    <div className="container-fluid p-0 set-height">
     {currentIndex === 0 &&
       <img src={Image1} alt="Logo" className="set-boat20" />
      }
      {currentIndex === 1 &&  wrongAnswers === false && score === 1 ?
       <img src={Image1} alt="Logo" className="set-boat21" />
      :
      currentIndex === 1 &&  wrongAnswers === true && score === 1 &&
       <img src={Image1} alt="Logo" className="set-boat21-move" />
      }
      {currentIndex === 2 && wrongAnswers === false && score === 1 ?
       <img src={Image1} alt="Logo" className="set-boat21" />
       :
       currentIndex === 2 && wrongAnswers === false && score === 2 ?
       <img src={Image1} alt="Logo" className="set-boat22" />
      :
      currentIndex === 2 && wrongAnswers === true && (score === 2 || score === 1) &&
      <img src={Image1} alt="Logo" className="set-boat22-move"/>
     }
     {currentIndex === 2 && wrongAnswers === true && score === 1 ?
     <>
      <img src={Image1} alt="Logo" className="set-boat22-move"/>
      <div className="result-fail">
      <h1>
        You're Lost !
      </h1>
       <button onClick = {handleDetour} className="detour">Sail Back</button> 
      </div>
     </>
      :
       currentIndex === 2 && wrongAnswers === true && score === 1 && firstIndex === false &&
       <img src={Image1} alt="Logo" className="set-boat22-move" />
    }
    {/* {currentIndex === 3 && wrongAnswers === true && score === 1 && firstIndex === true &&
    <img src={Image1} alt="Logo" className="set-boat22-move" />
    }
    {currentIndex === 3 && wrongAnswers === false && score === 1 &&
       <img src={Image1} alt="Logo" className="set-boat21" />
    }
    {currentIndex === 3 && wrongAnswers === false && score === 2 && firstIndex === true ?
      <img src={Image1} alt="Logo" className="set-boat-last-index" />
    :
    currentIndex === 3 && wrongAnswers === false && score === 2 && firstIndex === false &&
    <img src={Image1} alt="Logo" className="set-boat-last-index" />
    }
    {currentIndex === 3 && score === 3 && wrongAnswers === false &&
     <img src={Image1} alt="Logo" className="set-boat23" />
    } */}
    {wrongAnswers === true && score === 0 &&
      <img src={Image1} alt="Logo" className="set-boat21-move" />
     }
      {questions.length > 0 ? (
        <>
         {currentIndex === 2 && score!==2 &&
            <div className="result-fail">
            <h1>
              You're Lost !
            </h1>
             <button onClick = {handleDetour} className="detour">Sail Back</button> 
            </div>
          }
            {currentIndex!==2 &&
            <Questionaire
              handleAnswer={handleAnswer}
              showAnswers={showAnswers}
              handleNextQuestion={handleNextQuestion}
              data={questions[currentIndex]}
            />
            } 

        </>
      ) : (
        <div className="container set-center">
          <Spinner />
        </div>
      )}
    </div>
    }
  </>
  );
}
export default connect(null, {SocialEngineeringModule}) (Question);
