import React from "react";

export const Congratulationspopup = ({getusernameplayer2, currentModuleName, currentModuleName2, getusername, won, text, totaltimeSpent,totalTimeSpent2, closePopup }) => {
   

  return (
    <div className="popup-container">
     <div className="popup-body">
        <div className="row p-3">
            <div className="col-md-9">

            </div>
        <div className="col-md-3 text-end">
        <button className="close-btn" onClick={closePopup}>X</button>
        </div>
        </div>
      <h1 style={{fontStyle:"oblique", fontSize:"40px"}}>{won}</h1>
      {currentModuleName === "SOCIAL ENGINEERING" && currentModuleName2 === "SOCIAL ENGINEERING" ? 
      <>
      {totaltimeSpent > totalTimeSpent2 ? 
      <h1>{getusernameplayer2  ? getusernameplayer2 : ""}</h1>
      :
      <h1>{getusername ? getusername : ""}</h1>
      }
      {totaltimeSpent > totalTimeSpent2 ?
      <h1 className="total-time-spent">{text} {<p style={{color:"#B0B941", fontSize:"40px"}}>{totalTimeSpent2} secs</p>} </h1>
      : 
      <h1 className="total-time-spent">{text} {<p style={{color:"#B0B941", fontSize:"40px"}}>{totaltimeSpent} secs</p>} </h1>
      }
      </>
      :
      <>
      {currentModuleName === "SOCIAL ENGINEERING" ?
      <>
       <h1>{getusername ? getusername : ""}</h1>
       <h1 className="total-time-spent">{text} {<p style={{color:"#B0B941", fontSize:"40px"}}>{totaltimeSpent} secs</p>} </h1>
       </>
      :
      <>
      {currentModuleName2 === "SOCIAL ENGINEERING" &&
      <>
      <h1>{getusernameplayer2  ? getusernameplayer2 : ""}</h1>
      <h1 className="total-time-spent">{text} {<p style={{color:"#B0B941", fontSize:"40px"}}>{totalTimeSpent2} secs</p>} </h1>
     </>
      }
      </>
      }
      </>
      }
     </div>
    </div>
  );
};