import * as action_types from "../actionTypes";
const initializeState = {
  login: null,
  validate:"",
  phishingScore: null,
  malwareScore: null,
  ransomewareScore: null,
  insiderthreatScore: null,
  socialScore: null,
  denialScore: null,
  storeAttendeeId: null,
  urlloginresponse:null,
  urlloginresponseresult:null,
  adminLogin: null,
  adminLogout:null,
  checkstatus:null,
  checkstatus2:null,
  adminSearchUser:null,
  adminSearchUser2:null,
  topusers:null,
  topuserRefreshing: false
};

const GameReducer = (state = initializeState, action) => {
  switch (action.type) {
    case action_types.LOGIN:
      return { ...state, login: action.payload };
      case action_types.AUTH_TOKEN:
        return { ...state, validate: action.payload };
      case action_types.ADMIN_LOGIN:
      return { ...state, adminLogin: action.payload };
      case action_types.ADMIN_CHECK_STATUS:
      return { ...state, checkstatus: action.payload };
      case action_types.ADMIN_CHECK_STATUS_SECOND:
        return { ...state, checkstatus2: action.payload };
      case action_types.TOP_USERS_OF_DAY:
        return { ...state, topusers: action.payload, topuserRefreshing: action.payload.topuserRefreshing };
      case action_types.ADMIN_LOGOUT:
        return { ...state, adminLogout: action.payload };
      case action_types.SEARCH_USER:
      return { ...state, adminSearchUser: action.payload };
      case action_types.SEARCH_USER_PLAYER2:
      return { ...state, adminSearchUser2: action.payload };
    case action_types.PHISHINGMODULE:
      return { ...state, phishingScore: action.payload };
    case action_types.MALWAREMODULE:
      return { ...state, malwareScore: action.payload };
    case action_types.RANSOMEWAREMODULE:
      return { ...state, ransomewareScore: action.payload };
    case action_types.INSIDERTHREAT:
      return { ...state, insiderthreatScore: action.payload };
    case action_types.SOCIALENGINEERINGMODULE:
      return { ...state, socialScore: action.payload };
    case action_types.DENIALSERVICE:
      return { ...state, denialScore: action.payload };
      case action_types.URL_PARAMS:
        return { ...state, urlloginresponse: action.payload,
          urlloginresponseresult:action.payload.payload
         };
    default:
      return state;
  }
};
export default GameReducer;
