import React, { useState, useRef } from "react";
import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Login } from "../../redux/action/action";
import Recaptcha from "react-google-invisible-recaptcha";
import { connect } from "react-redux";
import {
  ClientId,
  SecretId,
  API_Prefix,
  REACT_APP_SITE_KEY,
} from "../config/config";

let server_url = `${API_Prefix}/o/`;
let data = `grant_type=client_credentials&client_id=${ClientId}&client_secret=${SecretId}`;
let accessToken = null;
let credentails = null;

const Loginform = (props) => {
  const captchaRef = useRef(null);
  const min = 1;
  const max = 10;
  const [required, setRequired] = useState(false);
  const [anotherEmail, anotherEmailRequired] = useState(false);
  const [corporateEmail, setCorporateEmail] = useState(false);
  const [emailRequired, setEmailRequired] = useState(false);
  const [nameRequired, setNameRequired] = useState(false);
  const [value, setValue] = useState();
  const [state, setstate] = useState({ name: "", email: "" });
  const navigate = useNavigate();

  React.useEffect(() => {
    // POST request to get Token using fetch()
    fetch(server_url + "oauth2/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: data,
    })
      .then((response) => response.json())
      .then((json) => {
        accessToken = json.access_token;
      });
  }, []);
  if (
    props.prGameAttendeeId &&
    props.prGameAttendeeId.data &&
    props.prGameAttendeeId.data.status === "true"
  ) {
    navigate({ pathname: "/cyberIsland" });
  }
  const handleNavigation = async (e) => {
    e.preventDefault();

    const data = {
      name: state.name,
      email: state.email,
    };
    credentails = data;

    if (data.name === "" && data.email === "") {
      setRequired(true);
      this.recaptcha.reset();
    } else if (data.email === "") {
      setEmailRequired(true);
    } else if (data.name === "") {
      setNameRequired(true);
    }
    if (data.name !== "" && data.email !== "") {
      setRequired(false);
      if (
        data.email.indexOf("@gmail.com") > 0 ||
        data.email.indexOf("@hotmail.com") > 0 ||
        data.email.indexOf("@ymail.com") > 0 ||
        data.email.indexOf("@live.com") > 0 ||
        data.email.indexOf("@yahoo.com") > 0 ||
        data.email.indexOf("@outlook.com") > 0
      ) {
        setCorporateEmail(true);
        setRequired(false);
        anotherEmailRequired(false);
      } else if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
        captchaRef.current.execute();
        // if (
        //   props.prGameAttendeeId &&
        //   props.prGameAttendeeId.data &&
        //   props.prGameAttendeeId.data.status === "true"
        // ) {
        //   navigate({ pathname: "/cyberIsland" });
        // }
        // props.Login(data, accessToken);
        // navigate({ pathname: "/cyberIsland" });
        setRequired(false);
      } else {
        anotherEmailRequired(true);
        setRequired(false);
      }
    }
  };
  const handleChange = (event) => {
    const challengevalue = Math.max(
      min,
      Math.min(max, Number(event.target.value))
    );
    setValue(challengevalue);

    const { name, value } = event.target;
    setstate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function onResolved() {
    const data1 = {
      name: credentails.name,
      email: credentails.email,
      token: captchaRef.current.getResponse(),
    };
    props.Login(data1, accessToken);
    props.LoginCredendails(data1.email);
  }

  return (
    <section className="login-bg">
      <div className="container-fluid">
        <div className="d-flex justify-content-center align-items-center vh-100">
          <form className="login-form-bg">
            <div className="d-flex flex-row align-items-center justify-content-center signup">
              <h1 className="lead fw-normal mb-0 me-3">Sign Up</h1>
            </div>
            <div className="inner-div">
              <div className="form-outline mb-4">
                <TextField
                  inputProps={{
                    autoComplete: "new-name",
                  }}
                  size="small"
                  label="Enter Name"
                  variant="filled"
                  fullWidth
                  type="text"
                  name="name"
                  value={state.name}
                  onChange={handleChange}
                />
                {required === true && (
                  <span style={{ color: "red" }}>*Required</span>
                )}
                {nameRequired === true && (
                  <span style={{ color: "red" }}>*Required</span>
                )}
              </div>
              <div className="form-outline mb-3">
                <TextField
                  inputProps={{
                    autoComplete: "new-email",
                  }}
                  size="small"
                  label="Enter Email"
                  variant="filled"
                  fullWidth
                  name="email"
                  type="email"
                  value={state.email}
                  onChange={handleChange}
                />
                {required === true && (
                  <span style={{ color: "red" }}>*Required</span>
                )}
                {emailRequired === true && (
                  <span style={{ color: "red" }}>*Required</span>
                )}
                {anotherEmail === true && (
                  <span style={{ color: "red" }}>Please enter an Email</span>
                )}
                {corporateEmail === true && (
                  <span style={{ color: "red" }}>
                    Please enter a corporate Email
                  </span>
                )}
              </div>
              <Recaptcha
                ref={captchaRef}
                sitekey={REACT_APP_SITE_KEY}
                onResolved={onResolved}
              />

              <div className="text-center text-lg-start mt-4 pt-2 set-btn">
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  onClick={(e) => handleNavigation(e)}
                >
                  Let's Play
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
const mapStateToProps = (state) => {
  return {
    prGameAttendeeId: state.GameReducer.login,
  };
};
export default connect(mapStateToProps, { Login })(Loginform);
