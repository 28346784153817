import React, { useState, useRef, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Adminlogin } from "../../redux/action/action";
import Recaptcha from "react-google-invisible-recaptcha";
import { connect } from "react-redux";
import {
  ClientId,
  SecretId,
  API_Prefix,
  REACT_APP_SITE_KEY
} from "../config/config";

let server_url = `${API_Prefix}/o/`;
let data = `grant_type=client_credentials&client_id=${ClientId}&client_secret=${SecretId}`;
let accessToken = null;
let credentails = null;

const AdminLogin = (props) => {
  const captchaRef = useRef(null);
  const min = 1;
  const max = 10;
  const [required, setRequired] = useState(false);
  const [anotherEmail, anotherEmailRequired] = useState(false);
  const [corporateEmail, setCorporateEmail] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [nameRequired, setNameRequired] = useState(false);
  const [value, setValue] = useState();
  const [state, setstate] = useState({ name: "", password: "" });
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token",null);
  const [wrongPassword, setwrongPassword] = useState("");
  let gethandleName;
  

  useEffect(()=>{

    if(props.adminLogin && props.adminLogin.data && props.adminLogin.data.status === "Success" && required === false &&
    props && props.adminLogout === null || props.adminLogout && props.adminLogout.data && props.adminLogout.data.status && props.adminLogout.data.status !=="Success")
    {

    
      setwrongPassword("");
      navigate({ pathname: `/admin?${token}` });
    }
   
    if(props.adminLogin && props.adminLogin.data && props.adminLogin.data.authToken === "")
    {

      setwrongPassword("Enter correct password");
    }
  },[props.adminLogin, props.adminLogout]);

 
  React.useEffect(() => {
    // POST request to get Token using fetch()
    
    fetch(server_url + "oauth2/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: data,
    })
      .then((response) => response.json())
      .then((json) => {
        accessToken = json.access_token;
      });
  }, []);

  const handleNavigation = async (e) => {
    e.preventDefault();
    captchaRef.current.reset();
 
    const data = {
      name: state.name,
      password: state.password,
    };
   
    credentails = data;
    
    if (data.name === "" && data.password === "") {
      setRequired(true);
      // this.recaptcha.reset();
      captchaRef.current.reset();
    } else if (data.password === "") {
      setwrongPassword("");
      setPasswordRequired(true);
      setwrongPassword("");
    } else if (data.name === "") {
      setNameRequired(true);
      setwrongPassword("");
    }
    else
    {
    if (data.name !== "" && data.password !== "") {
        if(required === false)
        {
         onResolved();
        };
      
        captchaRef.current.execute();
        setRequired(false);
        setPasswordRequired(false);
        setwrongPassword("");
    }
    }
  }
 
  const handleChange = (event) => {
    const challengevalue = Math.max(
      min,
      Math.min(max, Number(event.target.value))
    );
    setValue(challengevalue);

    const { name, value } = event.target;
    setstate((prevState) => ({
      ...prevState,
      [name]: value,
      
    }));
  };

  function onResolved() {
  
    const data1 = {
      username: credentails.name && credentails.name || gethandleName,
      password: credentails.password,
      captchaToken: captchaRef.current.getResponse(),
    };
   if(data1.captchaToken !=="")
   {
    props.Adminlogin(data1, accessToken);
   }
    gethandleName = data1.username;
    setstate("");
  }

  return (
    <section className="admin-login-bg">
      <div className="container-fluid">
        <div className="d-flex justify-content-center align-items-center vh-100">
          <form className="login-form-bg">
            <div className="d-flex flex-row align-items-center justify-content-center signup">
              <h1 className="lead fw-normal mb-0 me-3">Admin Login</h1>
            </div>
            <div className="inner-div">
              <div className="form-outline mb-4">
                <TextField
                  inputProps={{
                    autoComplete: "new-name",
                  }}
                  size="small"
                  label="Username"
                  variant="filled"
                  fullWidth
                  type="text"
                  name="name"
                  value={state.name}
                  onChange={handleChange}
                />
                {required === true && (
                  <span style={{ color: "red" }}>*Required</span>
                )}
                {nameRequired === true && (
                  <span style={{ color: "red" }}>*Required</span>
                )}
              </div>
              <div className="form-outline mb-3">
                <TextField
                  inputProps={{
                    autoComplete: "new-email",
                  }}
                  size="small"
                  label="Password"
                  variant="filled"
                  fullWidth
                  name="password"
                  type="password"
                  value={state.password}
                  onChange={handleChange}
                />
                {required === true && (
                  <span style={{ color: "red" }}>*Required</span>
                )}
                {passwordRequired === true && (
                  <span style={{ color: "red" }}>*Required</span>
                )}
                {anotherEmail === true && (
                  <span style={{ color: "red" }}>Enter correct password</span>
                )}
                {corporateEmail === true && (
                  <span style={{ color: "red" }}>
                    Please enter a corporate Email
                  </span>
                )}
                <span style={{ color: "red" }}>{wrongPassword}</span>
              </div>
              <Recaptcha
                ref={captchaRef}
                sitekey={REACT_APP_SITE_KEY}
                onResolved={onResolved}
              />

              <div className="text-center text-lg-start mt-4 pt-2 set-btn">
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  onClick={(e) => handleNavigation(e)}
                >
                 Login
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
const mapStateToProps = (state)=>{
  return{
     adminLogin: state.GameReducer.adminLogin,
     adminLogout: state.GameReducer.adminLogout
  }
}
export default connect(mapStateToProps, { Adminlogin })(AdminLogin);
