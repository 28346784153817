export function Data() {
    return [
            {
                "category": "Science Computers",
                 "correct_answer": "All options are correct",
                 "difficulty": "easy",
                 "incorrect_answers": [
                        "Look out for characteristics of suspicious emails",
                        "Report the email through the Phishing Reporter Button",
                        "Inform the Information Security Department",
                ],
                "question": "What should you do if you ever receive a suspicious email? ",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "URL does not match that of the legitimate site",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "No grammatical errors in the email body",
                    "Official email from IT Team to notify you of security tips",
                    "Lock sign in the URL"
                ],
                "question": "Which of the below is a sign of a phishing attack?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "It is a malicious attempt to obtain sensitive information",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "It is an email that automatically encrypts sensitive data",
                    "It is a program makes your system faster",
                    "It is a technique that helps catch more fish "
                ],
                "question": "Which of the following best describes phishing?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "Login credentials & company data",
                 "difficulty": "easy",
                 "incorrect_answers": [
                     "Publicly available information",
                      "Social media posts",
                      "Biometric data"
                    
                ],
                "question": "What information can be acquired via Phishing?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "True",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "False",
                ],
                "question": "Phishing has the potential to cause a significant security breach.",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "Data Leakage",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Improved risk assessment",
                    "Strengthened system security",
                    "Increased Compliance"
                ],

                "question": "A successful phishing campaign can lead to:",
                 "type": "multiple"
            },
        ]
}