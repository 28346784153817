import React from 'react';
import {useNavigate } from "react-router-dom";
// import Malwarescroll from "../Rander/banners/Malware.gif";
import Image1 from "../Rander/malwareboat.png";

const Toolkit = () => {

const navigate = useNavigate();

const handleNavigation = () => {
  navigate({pathname:"/malwareQuestion",
});
}
  return (
  <div className="container-fluid p-0 phishing-module">
  <img src={Image1} alt="Logo" className="set-boat-malware"/>
  <div className="d-flex vh-100">
    <div className="splash">
      {/* <img src={Image} alt="Logo" /> */}
      <button className="phishing-gamestart-btn" onClick={() => handleNavigation()}>
       Next
      </button>
      <div className='toolkit-scroll'>
      <img src="https://dqib2w4u3w0ne.cloudfront.net/Rander/banners/Malware.gif" alt="Logo" />
      </div>
    </div>
  </div>
</div>
  )
}
export default Toolkit
