export function Data() {
    return [
            {
                "category": "Science Computers",
                 "correct_answer": "Do not download or open the file and report the email immediately",
                 "difficulty": "easy",
                 "incorrect_answers": [
                        "Download the attachment and open it to see what it contains",
                        "Forward the email to your friends or colleagues to get their opinions",
                        "Delete the email immediately to be safe"
                ],
                "question": "What should you do if you receive a suspicious email with an attachment?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "Researching their intended victims on social media sites",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Getting a job at the target organization",
                    "Hacking the target organization directly",
                    "Installing malware on the victim’s system"
                ],
                "question": "To make social engineering attacks successful, attackers often prepare by:",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "Posting limited information on social media sites",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Installing antimalware solutions",
                    "Setting strong and unique passwords",
                    "Avoid using social media platforms and email"
                ],
                "question": "What are some techniques to avoid social engineering?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "Shoulder Surfing",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Clear Desk Clear Screen",
                    "Location Tracing",
                    "Malware Attack"
                ],
                "question": "Which one of these is a type of social engineering?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "Date of Birth",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "OTPs and Passwords",
                    "Tax Records",
                    "Access Cards"
                ],
                "question": "Hackers can attain which of the following things from your social media handles?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "Ransomware",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Phishing",
                    "Impersonation",
                    "Vishing"
                    
                ],
                "question": "Which of these is NOT a Social Engineering tactic? ",
                 "type": "multiple"
            },
        ]
}