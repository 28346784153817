import React , {useState } from 'react';
import  NavbarCustom  from '../../component/navabr/navbarCustom';
import LogoutCustom from '../logout/logout';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Congratulationspopup } from '../congratulationspopup/congratulationspopup';
import { useNavigate } from "react-router-dom";
import LeaderBoardScreen from '../leaderboardscreen/leaderBoardScreen';
import { AuthToken,AdminCompetitonSearch,AdminCompetitonSearch2,AdminCheckUserStatus,AdminCheckUserStatus2 } from "../../redux/action/action";
import { connect } from "react-redux";
import {
  ClientId,
  SecretId,
  API_Prefix
} from "../config/config";
let server_url = `${API_Prefix}/o/`;
let data = `grant_type=client_credentials&client_id=${ClientId}&client_secret=${SecretId}`;
let accessToken = null;

const CompetitionScreen = (props) => {

    const [open, setOpen] = useState(false);
    const [congrats, setcongrats] = useState(false);
    const [getusername, setusername] = useState("");
    const [leaderstate, setleaderstate] = useState(false);
    const [getusernameplayer2, setusernameplayer2] = useState("");
    const [getGameAttendeeIdPlayer,setGameAttendeeIdPlayer] = useState(null);
    const [getGameAttendeeIdPlayer2,setGameAttendeeIdPlayer2] = useState(null);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const getUsers = props && props.adminSearchUser && props.adminSearchUser.data && props.adminSearchUser.data.users || "";
    const getUsers2 = props && props.adminSearchUser2 && props.adminSearchUser2.data && props.adminSearchUser2.data.users || "";
  
    const [selectedRow, setSelectedRow] = React.useState(-1);
    const [selectedRow2, setSelectedRow2] = React.useState(-1);
    const handleClose1 = () => setShow1(false);
    const handleClose2 = () => setShow2(false);
    const [state, setstate] = useState({ name: "", email: "" });
    const navigate = useNavigate();
    const authToken = sessionStorage.getItem("token",null);
    const message = props.validate && props.validate.data && props.validate.data.status;
  

    // if(props && props.adminSearchUser && props.adminSearchUser.data && props.adminSearchUser.data.status === "Error")
    // {
    //   navigate({ pathname: "/admin" });
    // }
    // if(props && props.adminSearchUser2 && props.adminSearchUser2.data && props.adminSearchUser2.data.status === "Error")
    // {
    //   navigate({ pathname: "/admin" });
    // }

    React.useEffect(() => {
      // POST request to get Token using fetch()
      fetch(server_url + "oauth2/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          accessToken = json.access_token;
          props.AuthToken(accessToken,authToken);
        });
    }, []);

    /* Check User Status API */
    React.useEffect(() => {
      let interval;
      if(props && props.checkstatus && props.checkstatus.data && props.checkstatus.data.currentModuleName &&
        props.checkstatus.data.currentModuleName === "SOCIAL ENGINEERING" && getusername !== "")
      {
      
        if(open === false)
        {
         
          props.AdminCheckUserStatus(getGameAttendeeIdPlayer,accessToken, authToken);
          setcongrats(true);
          setOpen(true);
        }
        else{
        setcongrats(true);
        setOpen(true);
        }
      }
      else{
     
        if(props && props.checkstatus2 && props.checkstatus2.data && props.checkstatus2.data.currentModuleName &&
          props.checkstatus2.data.currentModuleName === "SOCIAL ENGINEERING" && getusernameplayer2 !== "")
        {
          setcongrats(true);  
          setOpen(true);  
        }
        else{
          setcongrats(false);  
          setOpen(false);  
        }
        interval = setInterval(() => {
        if(getGameAttendeeIdPlayer!== null && authToken !== null && message === "Success")
        {
          if(props && props.checkstatus2 && props.checkstatus2.data && props.checkstatus2.data.currentModuleName &&
            props.checkstatus2.data.currentModuleName === "SOCIAL ENGINEERING" && getusernameplayer2 !== "")
         {
         }
         else{
        props.AdminCheckUserStatus(getGameAttendeeIdPlayer,accessToken, authToken);
         }
        // setcongrats(false);  
        // setOpen(false);  
        }
        else{
          if(message === "Error")
          {
          navigate({ pathname: "/admin" });
          }
        }
      
      }, 5000)};
      return () => clearInterval(interval);
    }, [getGameAttendeeIdPlayer, props.checkstatus2, props.checkstatus, getusername]);


    /* Check User 2 Status API */
    React.useEffect(() => {
      let interval;
      if(props && props.checkstatus2 && props.checkstatus2.data && props.checkstatus2.data.currentModuleName &&
        props.checkstatus2.data.currentModuleName === "SOCIAL ENGINEERING" && getusernameplayer2 !== "")
     {
     
      if(open === false)
      {
      
        props.AdminCheckUserStatus(getGameAttendeeIdPlayer,accessToken, authToken);
        setcongrats(true);
        setOpen(true);
      }
      else{
       
      setcongrats(true);
      setOpen(true);
      }
     }
     else{  
    
      if(props && props.checkstatus && props.checkstatus.data && props.checkstatus.data.currentModuleName &&
        props.checkstatus.data.currentModuleName === "SOCIAL ENGINEERING" && getusername !== "")
      {
        setcongrats(true);
        setOpen(true);
      }
      else{
        setcongrats(false);
        setOpen(false);
      }
      interval = setInterval(() => {
        if(getGameAttendeeIdPlayer2 !==null && authToken !== null && message === "Success")
        {
          if(props && props.checkstatus && props.checkstatus.data && props.checkstatus.data.currentModuleName &&
            props.checkstatus.data.currentModuleName === "SOCIAL ENGINEERING" && getusername !== "")
          {
          }
          else{
           props.AdminCheckUserStatus2(getGameAttendeeIdPlayer2,accessToken, authToken);
          }
        }
        else{
          if(message === "Error")
          {
          navigate({ pathname: "/admin" });
          }
        }
      }, 5000)};
      return () => clearInterval(interval);
    }, [getGameAttendeeIdPlayer2, props.checkstatus, props.checkstatus2, getusernameplayer2]);

    const handleShow2 = () => 
    {
      setstate("");
      setShow2(true);
    }
    const handleShow1 = () => {
      setstate("");
      setShow1(true);
    }

    const handleNavigation = async (e) => {
      e.preventDefault();
    
      if (state.name !== "" && state.email !== "" && message === "Success") {
        if(props && props.checkstatus && props.checkstatus.data && props.checkstatus.data.currentModuleName &&
          props.checkstatus.data.currentModuleName === "SOCIAL ENGINEERING" && getusername !== "" )
        {
          
          setcongrats(true);
          setOpen(true);
        }
        else{
          props.AdminCompetitonSearch(state.name, state.email, accessToken, authToken);
          setcongrats(false);
          setOpen(false);
        }
      }
      else{
        props.AdminCompetitonSearch(state.name, state.email, accessToken, authToken);
        if(message === "Error")
        {
        navigate({ pathname: "/admin" });
        }
      }  
  }
  const handleNavigation2 = async (e) => {
    e.preventDefault();
    if (state.name !== "" && state.email !== "" && message === "Success") {
      if(props && props.checkstatus2 && props.checkstatus2.data && props.checkstatus2.data.currentModuleName &&
        props.checkstatus2.data.currentModuleName === "SOCIAL ENGINEERING" && getusername !== "" )
      {
       
        setcongrats(true);
        setOpen(true);
      }
      else{
         props.AdminCompetitonSearch2(state.name, state.email, accessToken, authToken);
        setcongrats(false);
        setOpen(false);
      }
     
    }
    else{
      props.AdminCompetitonSearch2(state.name, state.email, accessToken, authToken);
      
      if(message === "Error")
      {
      navigate({ pathname: "/admin" });
      }
    }  
}
  function handleChange(evt) {
    const value = evt.target.value;
    setstate({
      ...state,
      [evt.target.name]: value
    });
  }

   let handleRow=(username, index, prGameAttendeeId)=>{
   
    if(props && props.checkstatus && props.checkstatus.data && props.checkstatus.data.currentModuleName &&
      props.checkstatus.data.currentModuleName === "SOCIAL ENGINEERING" && getusername !== "")
    {
        setcongrats(true);
        setOpen(true);
    }
  
    setGameAttendeeIdPlayer(prGameAttendeeId);
    setSelectedRow(index);
    setusername(username);
    setShow1(false);
  }
   let handleRowPlayer2 = (username, index,prGameAttendeeId)=>{
    if(props && props.checkstatus2 && props.checkstatus2.data && props.checkstatus2.data.currentModuleName &&
      props.checkstatus2.data.currentModuleName === "SOCIAL ENGINEERING" && getusernameplayer2 !== "")
   {
    setcongrats(true);
    setOpen(true);
   }
  
    setGameAttendeeIdPlayer2(prGameAttendeeId);
    setSelectedRow2(index);
    setusernameplayer2(username);
    setShow2(false);
  }


  return (
   <>
   {leaderstate === false ? 
   
     <div className='container-fluid admin-screen'>
        <div className='row d-flex'> 
        

          {/* ................ Calling Congratulations POPUP Component .................. */}

          {congrats === true && 
         <>
          {open ? <Congratulationspopup getusername = {getusername} getusernameplayer2 = {getusernameplayer2} won = {"Congratulations!"} text ={"You have reached Cyber Island in"}
           totaltimeSpent={props && props.checkstatus && props.checkstatus.data && props.checkstatus.data &&
           props.checkstatus.data.totalTimeSpent}
           totalTimeSpent2 ={props.checkstatus2 && props.checkstatus2.data && props.checkstatus2.data &&
            props.checkstatus2.data.totalTimeSpent}
          currentModuleName={props && props.checkstatus && props.checkstatus.data && props.checkstatus.data.currentModuleName &&
            props.checkstatus.data.currentModuleName}
            currentModuleName2 = {props && props.checkstatus2 && props.checkstatus2.data && props.checkstatus2.data.currentModuleName &&
              props.checkstatus2.data.currentModuleName}
           closePopup={() => {
            setOpen(false); 
            setusername("")
            setusernameplayer2("")}
            } /> : null}
          </>
         }
     


        <div className='w-18'>
        <NavbarCustom setleaderstate = {setleaderstate}/>
        </div>
            <div className='w-35' style={{display:"-webkit-inline-box"}}>
            <p className='player-text-1 mt-4 p-1'>
            <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/blue-gif-2.gif' className='players-icon-top mx-1' />
            {getusername!=="" ? getusername : "Player 1"}
            </p>
            <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/icons-03.png' className='menu-icon mt-3 mx-3' title='Search' onClick={handleShow1}/>
            <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
          <Modal.Title>Player 1 Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="pb-4" >
              {/* <Form.Label>Player Name</Form.Label> */}
              <Form.Control
                type="text"
                placeholder="Player Name"
                name="name"
                value={state.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="pb-4" >
              {/* <Form.Label>Email address</Form.Label> */}
              <Form.Control
                type="email"
                name="email"
                placeholder="Player Email"
                value={state.email}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
          <Modal.Footer>
          <Button className='custom-btn' onClick={(e) => handleNavigation(e)}>
            Search
          </Button>
        </Modal.Footer> 

          <div class="container">
          <div class="row">
            <div class="col-12 comp">
              <table class="table table-bordered">
                <thead>
            <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
          </tr>
        </thead>
        <tbody>
          {getUsers && getUsers.length > 0 ? (
           getUsers && getUsers.map((result, index) => (
          <tr className={"clickable-row ".concat(selectedRow === index ? "selected" : "")} key={index} onClick={()=>handleRow(result.name, index, result.prGameAttendeeId)}>
            <td>{result.name}</td>
            <td>{result.email}</td>
        
          </tr>
           ))
          ):(
          "No User found"
          )}
        </tbody>
      </table>
    </div>
  </div>
</div>

      </Modal.Body>
      </Modal>
                
            </div>
            <div className='w-35' style={{display:"-webkit-inline-box"}}>
            <p className='player-text-1 mt-4 p-1'> <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/red-gif.gif' className='players-icon-top mx-1' />
            {getusernameplayer2!=="" ? getusernameplayer2 : "Player 2"}
            </p>
            <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/icons-03.png' className='menu-icon mt-3 mx-3' title='Search' onClick={handleShow2}/>
            <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Player 2 Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <Form.Group className="pb-4" >
              {/* <Form.Label>Player Name</Form.Label> */}
              <Form.Control
                type="text"
                placeholder="Player Name"
                name="name"
                value={state.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="pb-4" >
              {/* <Form.Label>Email address</Form.Label> */}
              <Form.Control
                type="email"
                name="email"
                placeholder="Player Email"
                value={state.email}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
          <Modal.Footer>
          <Button className='custom-btn' onClick={(e) => handleNavigation2(e)}>
            Search
          </Button>
        </Modal.Footer> 
          <div class="container">
          <div class="row">
            <div class="col-12 comp">
              <table class="table table-bordered">
                <thead>
            <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
          </tr>
        </thead>
        <tbody>
          {getUsers2 && getUsers2.length > 0 ? (
           getUsers2 && getUsers2.map((result, index) => (
          <tr className={"clickable-row ".concat(selectedRow2 === index ? "selected" : "")} key={index} onClick={()=>handleRowPlayer2(result.name, index, result.prGameAttendeeId)}>
            <td>{result.name}</td>
            <td>{result.email}</td>
        
          </tr>
           ))
          ):(
          "No User found"
          )}
        </tbody>
      </table>
    </div>
  </div>
</div>

      </Modal.Body>
      </Modal>
            </div>
            <div className='w-12 text-end'>
            <LogoutCustom/>
            </div>
        </div>
      
       
        


        <div className='row'>
        <div className='col-md-12'>
        {props && props.checkstatus && props.checkstatus.data && props.checkstatus.data.currentModuleName &&
         props.checkstatus.data.currentModuleName === "PHISHING" && getusername !== "" ?
        <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/blue-gif-2.gif' className='players-icon malware-players-icon' />
        :
        props && props.checkstatus && props.checkstatus.data && props.checkstatus.data.currentModuleName &&
         props.checkstatus.data.currentModuleName === "MALWARE" && getusername !== "" ?
        <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/blue-gif-2.gif' className='players-icon ransomeware-players-icon' />
        :
        props && props.checkstatus && props.checkstatus.data && props.checkstatus.data.currentModuleName &&
         props.checkstatus.data.currentModuleName === "RANSOMEWARE" && getusername !== "" ?
         <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/blue-gif-2.gif' className='players-icon social-players-icon' />
         :
         props && props.checkstatus && props.checkstatus.data && props.checkstatus.data.currentModuleName &&
         props.checkstatus.data.currentModuleName === "SOCIAL ENGINEERING" && getusername !== "" ?
         <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/blue-gif-2.gif' className='players-icon cyber-players-icon' />
         :
        <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/blue-gif-2.gif' className='players-icon player-position-start' />
        }

        {props && props.checkstatus2 && props.checkstatus2.data && props.checkstatus2.data.currentModuleName &&
         props.checkstatus2.data.currentModuleName === "PHISHING" && getusernameplayer2 !== "" ?
        <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/red-gif.gif' className='players-icon malware-players-icon-red ml-70' />
        :
        props && props.checkstatus2 && props.checkstatus2.data && props.checkstatus2.data.currentModuleName &&
         props.checkstatus2.data.currentModuleName === "MALWARE" && getusernameplayer2 !== "" ?
         <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/red-gif.gif' className='players-icon ransomeware-players-icon-red ml-70' />
        :
         props && props.checkstatus2 && props.checkstatus2.data && props.checkstatus2.data.currentModuleName &&
         props.checkstatus2.data.currentModuleName === "RANSOMEWARE" && getusernameplayer2 !== "" ?
         <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/red-gif.gif' className='players-icon social-players-icon-red ml-70' />
         :
         props && props.checkstatus2 && props.checkstatus2.data && props.checkstatus2.data.currentModuleName &&
         props.checkstatus2.data.currentModuleName === "SOCIAL ENGINEERING" && getusernameplayer2 !== "" ?
         <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/red-gif.gif' className='players-icon cyber-players-icon-red ml-70' />
         :

        <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/red-gif.gif' className='players-icon player-position-start ml-70' />
        }
        </div>
        </div>
   </div>

      :
      <LeaderBoardScreen/>
      }
    
   </>
  )
}
const mapStateToProps = (state)=>{
  return{
      adminLogin: state.GameReducer.adminLogin,
      validate: state.GameReducer.validate,
      adminSearchUser : state.GameReducer.adminSearchUser,
      adminSearchUser2 : state.GameReducer.adminSearchUser2,
      checkstatus: state.GameReducer.checkstatus,
      checkstatus2: state.GameReducer.checkstatus2
  }
}
export default connect(mapStateToProps, {AuthToken,AdminCompetitonSearch,AdminCompetitonSearch2,AdminCheckUserStatus,AdminCheckUserStatus2 })(CompetitionScreen);
