import React from 'react';
import {useNavigate } from "react-router-dom";
// import Image from "../Rander/Cyber-island-screen.gif";
// import Phishingscroll from "../Rander/banners/Phishing.gif";

const Toolkit = () => {

const navigate = useNavigate();

const handleNavigation = () => {
  navigate({pathname:"/question",
});
}
  return (
  <div className="container-fluid p-0 phishing-module">
  <div className="d-flex vh-100">
    <div className="splash">
      {/* <img src={Image} alt="Logo" /> */}
      <button className="phishing-gamestart-btn" onClick={() => handleNavigation()}>
        Next
      </button>
      <div className='toolkit-scroll'>
      <img src="https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/banners/phishing-scroll.gif" alt="Logo" />
      </div>
    </div>
  </div>
</div>
  )
}
export default Toolkit
