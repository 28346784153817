export function Data() {
    return [
            {
                "category": "Science Computers",
                 "correct_answer": "Refrain from downloading unidentified software or files",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "By using official workstations or laptops for personal use",
                    "By avoiding updating systems since updates may contain malware",
                    "By avoiding the use of internet"
                ],
                "question": "How should you keep your systems safe from malware?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "All options are correct",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "It spies on your online activities",
                    "It steals your passwords and other login credentials",
                    "It uses your system to spread to other computers on the network"
                ],
                "question": "What can happen when your system is affected by Malware?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "Ransomware",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Phishing",
                    "Social Engineering",
                    "Dumpster Diving"
                ],
                "question": "Which of the following is a type of malware?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "No",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Yes",
                ],
                "question": "Is it okay to use unknown USBs on laptops?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "Software & Hardware",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Hardware",
                    "Operating System only",
                    "Motherboard only"
                ],
                "question": "Which parts of your system can get affected by Malware?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "Malicious Software",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Malignant Software",
                    "Malicious Hardware",
                    "Malpractice Software"
                ],
                "question": "Malware is a combination of which of the following?",
                 "type": "multiple"
            },
        ]
}