// For localhost.................

//export const ClientId = "id-9e3917d0-22d2-aa25-87da-b3ac18c4d44f";
//export const SecretId = "secret-89d81c98-4e75-bce2-fe91-776ee8ac47c";
//export const API_Prefix = "http://localhost:8080";

export const REACT_APP_SITE_KEY = "6Lc_xNEZAAAAAMLQmdZ-QCRLmpW8k_96pgSEsEQS";

// For Live.......................

export const ClientId = "id-efd0639d-ef9b-3b67-b713-fa51f66cf39f";
export const SecretId = "secret-bfc4a225-d394-e26b-6c63-86e6f18c2";
export const API_Prefix = "https://phishrod.co";

// For Staging ....................

//export const ClientId = "id-9bbc15b6-6f21-511f-89d7-4a8d361f2570";
//export const SecretId = "secret-9a67f55a-4246-c31a-d911-2d8764695b5";
//export const API_Prefix = "http://192.168.139.222"

// For AWS Stage .................

//export const ClientId = "id-6f3d57c4-92ae-252a-80ca-d63dd32820";
//export const SecretId = "secret-48b662dc-6637-48c0-eee0-b5ee9ccac3d1";
//export const API_Prefix = "https://stage.phishrod.co";

// For Production ..................
